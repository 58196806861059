* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

@font-face {
  font-family: "MontserratBold";
  src: local("MontserratBold"),
    url("./fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "MontserratMedium";
  src: local("MontserratMedium"),
    url("./fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "MontserratSemiBold";
  src: local("MontserratSemiBold"),
    url("./fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
}
